import { REPORTS, BILLING, HISTORY } from '@/constants/routes';
import {
  mdiHistory as HistoryIcon,
  mdiCreditCardSettingsOutline as BillingIcon,
  mdiEqualizer as ReportsIcon,
} from '@mdi/js';
import { IMenuItem } from './MenuItem/types';

const menuItems: IMenuItem[] = [{
  key: 'reports', name: 'Reports', icon: ReportsIcon, route: REPORTS,
}, {
  key: 'billing', name: 'Billing', icon: BillingIcon, route: BILLING,
}, {
  key: 'history', name: 'History', icon: HistoryIcon, route: HISTORY,
}];

export default menuItems;
