import React, { FC, useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import { UserContext } from '@/providers/UserProvider';
import { TextField } from 'formik-material-ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Formik, Form, Field, FormikHelpers, FormikProps,
} from 'formik';
import { NotificationE } from '@/ui/Notification/types';
import { Notification } from '@/ui/Notification';
import { FirebaseContext } from '@/components/Firebase';
import { IFirebaseSingleton } from '@/components/Firebase/types';
import useStyles from './styles';
import { AuthErrorCode, IProps, IValues } from './types';
import initialValues from './init';

const ChangePassword: FC<IProps> = ({ open, setOpen }) => {
  const classes = useStyles();
  const firebaseApp = useContext(FirebaseContext);
  const user = useContext(UserContext);
  const email = user?.email || '';

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <section className={classes.containerDialog}>
          <div className={classes.title}>Change Password</div>
          <Formik
            initialValues={{ ...initialValues }}
            validate={handleValidate}
            onSubmit={handleSubmit(firebaseApp as IFirebaseSingleton)}
          >
            { (props) => handleRender(props) }
          </Formik>
        </section>
      </Dialog>
      <Notification
        isOpen={!!success}
        message={success}
        onClose={() => setSuccess('')}
        type={NotificationE.Success}
        delay={1000}
      />
      <Notification
        isOpen={!!error}
        message={error}
        onClose={() => setError('')}
        type={NotificationE.Error}
      />
    </>
  );

  function handleRender({ isSubmitting, submitForm }: FormikProps<IValues>) {
    return (
      <>
        <section className={classes.containerContent}>
          <Form>
            <Field
              component={TextField}
              id="old"
              required
              type="password"
              label="Old Password"
              name="old"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.input}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  shrink: classes.labelShrink,
                },
              }}
            />
            <Field
              component={TextField}
              id="new"
              required
              type="password"
              label="New Password"
              name="new"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.input}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  shrink: classes.labelShrink,
                },
              }}
            />
            <Field
              component={TextField}
              id="repeat"
              required
              type="password"
              label="Repeat New Password"
              name="repeat"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.input}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  shrink: classes.labelShrink,
                },
              }}
            />
          </Form>
        </section>
        <DialogActions>
          <Button onClick={handleClose} color="primary" className={classes.button}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
            className={classes.button}
          >
            Save
          </Button>
        </DialogActions>
      </>
    );
  }

  function handleValidate(values: IValues) {
    const errors: Partial<IValues> = {};
    const requiredFields = ['old', 'new', 'repeat'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    if (values.new !== values.repeat) {
      errors.repeat = 'New password is repeated incorrectly';
    }
    return errors;
  }

  function handleSubmit(firebaseApp: IFirebaseSingleton) {
    return async (values: IValues, { setSubmitting }: FormikHelpers<IValues>) => {
      try {
        setSubmitting(true);
        const { user } = await firebaseApp.doSignInWithEmailAndPassword(email, values.old);
        if (user) {
          user.updatePassword(values.new).then(() => {
            setSuccess('Password changed successfully!');
            handleClose();
          }).catch((error) => {
            setError(error.message);
          });
        } else {
          setError('Error!');
        }
      } catch (error) {
        if (error.code === AuthErrorCode.wrongPassword) {
          setError('Old password is incorrect');
        } else {
          setError(error.message);
        }
      } finally {
        setSubmitting(false);
      }
    };
  }
};

export default ChangePassword;
