import React, { FC } from 'react';
import List from '@material-ui/core/List';
import { useHistory, useLocation } from 'react-router-dom';
import useStyles from './styles';
import { MenuItem } from './MenuItem';
import { IMenuItem } from './MenuItem/types';
import menuItems from './config';
import { IProps } from './types';

const Sidebar: FC<IProps> = ({ open }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <List className={classes.container}>
      {menuItems.map((item: IMenuItem) => (
        <React.Fragment key={`${item.key}-sidebar`}>
          <MenuItem
            item={item}
            open={open}
            active={pathname === item.route}
            onClick={handleRoute}
          />
        </React.Fragment>
      ))}
    </List>
  );

  function handleRoute(route: string) {
    history.push(route);
  }
};

export default Sidebar;
