import React, {
  createContext, FC, useState, useEffect,
} from 'react';
import { Firebase } from '@/components/Firebase';
import { IUser, IUserProvider } from './types';

export const UserContext = createContext<IUserProvider>(null);

const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    const unsubscribeFromAuth = Firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const { email, uid } = userAuth;
        const userQuery = await Firebase.getFirestore().collection('profile').doc(uid).get();
        const profile = userQuery.data();
        setUser({ email: email || '', displayName: profile?.projectApp || '' });
      } else {
        setUser(userAuth);
      }
    });

    return () => unsubscribeFromAuth();
  }, []);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
