import React, { FC, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import * as ROUTES from '@/constants/routes';
import { Header } from './Header';
import useStyles from './styles';
import { Sidebar } from './Sidebar';

const Layout: FC = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const today = new Date();

  return (
    <>
      { ![ROUTES.SIGN_IN, ROUTES.RESET_PASSWORD].includes(pathname) ? (
        <>
          <Header handleDrawer={handleDrawer} />
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx(classes.drawerPaper, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <Sidebar open={open} />
          </Drawer>
          <main className={clsx(classes.mainContent, { [classes.main]: !open, [classes.mainOpen]: open })}>
            {children}
          </main>
          <footer className={classes.footer}>
            <p className={classes.footerText}>{`© ${today.getFullYear()} CTP, LLC. All rights reserved.`}</p>
            <p className={classes.footerText}>contact@architech.nyc</p>
          </footer>
        </>
      ) : (<>{children}</>)}
    </>
  );

  function handleDrawer() {
    setOpen(!open);
  }
};

export default Layout;
