export interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export interface IValues {
    old: string;
    new: string;
    repeat: string;
}

export enum AuthErrorCode {
    wrongPassword = 'auth/wrong-password'
}
