import React, { FC, ComponentType, useContext } from 'react';
import {
  Route, Redirect, RouteProps,
} from 'react-router';
import * as ROUTES from '@/constants/routes';
import { UserContext } from '@/providers/UserProvider';
import { getItem } from '@/utils/storage';
import { STORAGE_KEY_TOKEN } from '@/constants/storage';

const ProtectedRoute: FC<RouteProps & { component: ComponentType }> = ({ component: Component, ...rest }) => {
  const user = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => ((user || getItem(STORAGE_KEY_TOKEN))
        ? <Component {...props} />
        : <Redirect to={{ pathname: ROUTES.SIGN_IN, state: { from: props.location } }} />)}
    />
  );
};

export default ProtectedRoute;
