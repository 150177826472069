import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    color: '#fff',
    fontSize: '20px',
    fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
    marginLeft: theme.spacing(1),
  },
  containerToolbar: {
    paddingLeft: 0,
    height: '48px',
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(1),
    boxShadow: '0 3px 4px 0 rgba(0,0,0,.2), 0 3px 3px -2px rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12)',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    zIndex: 1101,
  },
  icon: {
    fill: '#fff',
    color: '#fff',
  },
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
