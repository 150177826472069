import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  containerDialog: {
    padding: '24px',
    width: '360px',
  },
  containerContent: {
    marginBottom: '24px',
  },
  button: {
    textTransform: 'none',
  },
  title: {
    color: 'black',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
    marginBottom: '24px',
  },
  input: {
    marginBottom: '8px',
  },
  labelRoot: {
    fontSize: '13px',
    fontWeight: 400,
    top: '2px',
  },
  labelShrink: {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

export default useStyles;
