import React, { FC, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButtonUI from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { SignOut } from '@/components/SignOut';
import useStyles from './styles';
import { IProps } from './types';

const Header: FC<IProps> = ({ handleDrawer }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.containerToolbar}>
        <section className={classes.containerHeader}>
          <Typography className={classes.toolbar}>
            <IconButtonUI onClick={handleDrawer} className={classes.iconButton}>
              <MenuIcon className={classes.icon} />
            </IconButtonUI>
          </Typography>
          <Typography variant="h6" className={classes.title}>
            Cloud Payment Portal
          </Typography>
        </section>
        <section className={classes.containerHeader}>
          <IconButtonUI onClick={(event) => setAnchorEl(event.currentTarget)} className={classes.iconButton}>
            <AccountCircleIcon className={classes.icon} />
          </IconButtonUI>
          <SignOut anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </section>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
