import React, { FC } from 'react';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Icon } from '@/ui/Icon';
import useStyles from './styles';
import { IProps } from './types';

const MenuItem: FC<IProps> = ({
  item, open, active, onClick,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      className={clsx(classes.column, { [classes.columnWrap]: !open, [classes.activeWrap]: active })}
      onClick={() => onClick(item.route)}
    >
      <ListItemIcon className={clsx(classes.columnIcon, { [classes.columnIconFull]: open })}>
        <Icon
          path={item.icon}
          className={clsx({ [classes.activeIcon]: active })}
        />
      </ListItemIcon>
      <ListItemText
        primary={item.name}
        disableTypography
        className={clsx(classes.columnText, { [classes.columnTextNone]: !open, [classes.activeText]: active })}
      />
    </ListItem>
  );
};

export default MenuItem;
