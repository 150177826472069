import { makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 249;
const drawerWidthClosed = 80;

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  main: {
    marginBottom: theme.spacing(10),
    marginLeft: `${drawerWidthClosed}px`,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainOpen: {
    marginBottom: theme.spacing(10),
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(8),
  },
  drawerPaper: {
    border: 'none',
    zIndex: 1099,
    borderRight: '1.1px solid rgba(0,0,0,.12)',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '67px',
  },
  footer: {
    background: theme.palette.background.default,
    height: '48px',
    position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 1100,
    width: '100%',
    borderTop: '1.1px solid rgba(0,0,0,.12)',
    fontSize: '11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${theme.spacing(3)}px`,
  },
  footerText: {
    marginBottom: theme.spacing(-1),
    opacity: 0.6,
    margin: 0,
  },
}));

export default useStyles;
