import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { IFirebaseSingleton } from './types';
import config from './config';

export const FirebaseContext = React.createContext<IFirebaseSingleton | null>(null);

class FirebaseSingleton implements IFirebaseSingleton {
  static instance: FirebaseSingleton;

  private app: firebase.app.App = firebase.initializeApp(config);

  public auth: firebase.auth.Auth = firebase.auth();

  private firestore: firebase.firestore.Firestore = firebase.firestore();

  constructor() {
    if (FirebaseSingleton.instance) {
      return this;
    }

    FirebaseSingleton.instance = this;
    return this;
  }

  doSignInWithEmailAndPassword = (
    email: string, password: string,
  ) => this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut();

  doSendPasswordResetEmail = (email: string) => this.auth.sendPasswordResetEmail(email);

  getFirestore = () => this.firestore;
}

const Firebase = new FirebaseSingleton();
Object.freeze(Firebase);

export default Firebase;
