import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    padding: theme.spacing(1),
    alignItems: 'unset',
    '&:hover': {
      backgroundColor: '#e1e1e1',
    },
  },
  columnWrap: {
    flexDirection: 'column',
  },
  columnIcon: {
    marginLeft: '13px',
  },
  columnIconFull: {
    marginLeft: '13px',
    minWidth: '45px',
  },
  columnText: {
    marginBottom: 0,
    fontSize: '13px',
  },
  columnTextNone: {
    display: 'none',
  },
  activeIcon: {
    fill: '#3367d6',
  },
  activeText: {
    color: '#3367d6',
  },
  activeWrap: {
    backgroundColor: '#f1f5fe',
  },
}));

export default useStyles;
