import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  containerText: {
    padding: '10px 20px',
    borderBottom: '1.1px solid rgba(0,0,0,.12)',
  },
  containerButton: {
    padding: '10px 20px',
    textAlign: 'end',
  },
  label: {
    fontSize: '16px',
    textTransform: 'capitalize',
  },
  text: {
    color: '#666',
  },
  button: {
    textTransform: 'none',
  },
  containerButtonPassword: {
    marginLeft: '-7px',
    marginTop: '16px',
  },
}));

export default useStyles;
