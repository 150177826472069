import ReactDOM from 'react-dom';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import theme from '@/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as serviceWorker from './serviceWorker';
import App from './app';
import { Firebase, FirebaseContext } from './components/Firebase';
import UserProvider from './providers/UserProvider';

const Component = () => (
  <FirebaseContext.Provider value={Firebase}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </UserProvider>
      </ThemeProvider>
    </BrowserRouter>
  </FirebaseContext.Provider>
);

serviceWorker.unregister();

ReactDOM.render(<Component />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept(['./app'], () => {
    ReactDOM.render(<Component />, document.getElementById('root'));
  });
}
