import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1a73e8',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#000',
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        fontSize: '13px',
        paddingTop: '8.5px',
        paddingBottom: '8.5px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '13px',
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 500,
        color: 'black',
      },
    },
  },
});

export default theme;
