import React, { FC, useContext, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { UserContext } from '@/providers/UserProvider';
import { removeItem } from '@/utils/storage';
import { STORAGE_KEY_TOKEN } from '@/constants/storage';
import useStyles from './styles';
import { IProps } from './types';
import { FirebaseContext } from '../Firebase';
import { ChangePassword } from './ChangePassword';

const SignOut: FC<IProps> = ({ anchorEl, setAnchorEl }) => {
  const classes = useStyles();
  const firebaseApp = useContext(FirebaseContext);
  const user = useContext(UserContext);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Popover
        id="preset-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <section>
          <section className={classes.containerText}>
            <div className={classes.label}>{user?.displayName}</div>
            <div className={classes.text}>{user?.email}</div>
            <section className={classes.containerButtonPassword}>
              <Button
                variant="text"
                color="primary"
                onClick={() => setOpen(true)}
                className={classes.button}
              >
                Change password
              </Button>
            </section>
          </section>
          <section className={classes.containerButton}>
            <Button variant="outlined" size="small" onClick={handleSignOut}>
              Sign out
            </Button>
          </section>
        </section>
      </Popover>
      <ChangePassword open={open} setOpen={setOpen} />
    </>
  );

  function handleSignOut() {
    firebaseApp?.doSignOut();
    setAnchorEl(null);
    removeItem(STORAGE_KEY_TOKEN);
  }
};

export default SignOut;
