import React, { FC, lazy, Suspense } from 'react';
import '@/theme/global.scss';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import * as ROUTES from '@/constants/routes';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import { Layout } from './Layout';

const LoginPage = lazy(() => import('./Auth/Login'));
const ResetPasswordPage = lazy(() => import('./Auth/ResetPassword'));
const ReportsPage = lazy(() => import('./Reports'));
const BillingPage = lazy(() => import('./Billing'));
const HistoryPage = lazy(() => import('./History'));

const App: FC = () => (
  <>
    <CssBaseline />
    <Helmet>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
    </Helmet>
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={ROUTES.SIGN_IN} component={LoginPage} />
          <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
          <ProtectedRoute exact path={ROUTES.REPORTS} component={ReportsPage} />
          <ProtectedRoute exact path={ROUTES.BILLING} component={BillingPage} />
          <ProtectedRoute exact path={ROUTES.HISTORY} component={HistoryPage} />
          {/* TODO: added page NotFoundPage */}
          {/* <Route path="*" component={NotFoundPage} /> */}
        </Switch>
      </Suspense>
    </Layout>
  </>
);

export default App;
